.navLink {
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    color: #101010;
    margin-right: 24px;
}
/* addes this to position calendly button in line */
.headerNav{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.headerLogo{
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    color: #101010;
}

.siteHeader{
    width: 100%;
    position: fixed;
    z-index: 3;
}
.siteHeader.active{
    /* background-color: #FFE7FB ; */
    background-color: white;
    
}
.headerContainer {
    box-sizing: border-box;
    height: 64px;
    padding-top: 16px;
    padding-bottom: 16px;
    align-items: center;
    border-bottom: 1px solid rgb(188, 188, 188);
    display: flex;
    justify-content: space-between;
}
.headerContainer.open{
    background-color: white;

}
.mobileToggle{
    display: none;
    align-items: center;
    justify-content: center;
    /* border: 1px solid lightgray; */
    border-radius: 2px;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
}
.navLink{
    cursor: pointer;
}
.headerLogo{
    cursor: pointer;
}

/* Media Queries */
@media screen and (max-width: 1100px) {
    .headerContainer {
        padding-left: 32px;
        padding-right: 32px;
    }
    
    /* .headerNav.open{
        align-items: start;
    } */
}
@media screen and (max-width: 760px) {
    .headerContainer {
        padding-left: 14px;
        padding-right: 14px;
        width: 100%;
    }
    /* Mobile Menu */
    .mobileToggle{
        display: flex;
    }
    .headerNav {
        box-sizing: border-box;
        /* background-color: #FFE7FB; */
        background-color: white;
        padding: 24px;
        position: fixed;
        width: 100%;
        top: -500px;
        left: 0px;
        display: flex;
        flex-direction: column;
        transition: 600ms;
    }
    .headerNav.open{
        top: 64px;
    }
    .headerNav .navLink{
        padding-top: 24px;
        padding-bottom: 24px;
        margin-right: 0;
    }
    .navLink{
        width: 100%;
    }
    .popup-button{
        width: 100%;
    }
    .button.primary.headerButton{
        width: 100%;
    }
}
@media screen and (max-width: 480px) {
    .headerContainer {
        padding-left: 32px;
        padding-right: 32px;
        
    }
}
@media screen and (max-width: 360px) {
    .headerContainer {
        padding-left: 32px;
        padding-right: 32px;
    }
}