.heroSection{
    padding-top: 64px;
    background-image: url("../assets/Group\ 48095598.png");
    background-position: 40% 40%;
}
.heroImage{
    max-width: 100%;
}
.heroContainer {
    display: flex;
    padding-bottom: 0;
}
.col {
    width: 50%;
}
.button.primary{
    cursor: pointer;
}
/* 
.bgGradient {
    background-image: radial-gradient(circle, rgba(92, 144, 222, 0.7)20%, transparent 80%),
                      radial-gradient(circle,rgba(255, 103, 255, 0.729)40%, transparent 70%),
                      radial-gradient(circle,rgba(246, 166, 27, 0.884)40%, transparent 80%),
                      radial-gradient(circle, rgb(253, 237, 56)50%, transparent 60%);
    filter: blur(40%);
    background-size: 400% 400%;
    animation: animateGradient 35s ease infinite;
} */
/*
.bgGradient{
    background-image: url("../assets/Group\ 48095598.png");
}*/
/* 
.bgGradient::before{
    display: block;
    position: absolute;
    z-index: -1;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("../assets/Group\ 48095598.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    animation: gradient 5s ease infinite;
} */
/* 
@keyframes gradient {
    0% { transform:rotate(0deg)}
    50%{transform:rotate(40deg)}
    100%{transform:rotate(0deg)}
    } */

/* 
@keyframes animateGradient {
    0% { background-position:0% 0%;}
    20% { background-position: 100% -30%;}
    30% { background-position: 30% 50%;}
    50% { background-position: 80% 100%;}
    70% { background-position: 80% 50%;}
    80% { background-position: 90% 20%;}
    90% { background-position: 0% 90%;}
    100%{background-position: 0% 0%;}
    } */

.textWrap{
    align-items: start;
    justify-content: center;
}
.headingTxt{
    width: 100%;
    align-items: start;
    color: #5201FF;
    font-size: 54px;
    font-family: 'Poppins';
    /* margin-top: 120px; */
    margin-bottom: 10px;
    margin-top: 0px;
    
}
.subTxt{
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    margin-top: 0px;
}
@media screen and (max-width: 1100px){
    .heroContainer{
        padding: 0 32px;
    }/*
    .bgGradient{
        background-position: 50px;
    }*/
    .headingTxt{
        line-height: 64px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 880px){
    .subTxt{
        margin-bottom: 20px;
        font-size:20px;
        line-height: 30px;
    }
    .headingTxt{
        line-height: 42px;
        font-size: 40px;
    }
}

@media screen and (max-width: 760px){
    .heroContainer{
        padding: 0 14px;
    }
    /*
    .bgGradient{
        background-position-x: -100px;
    }*/
    .col.imageWrap{
        justify-content: end;
    }
    
    
   
}

@media screen and (max-width: 650px){
    .subTxt{
        font-size: 18px;
        line-height: 24px;
    }
    .col.textWrap{
        width: 60%;
    }
    .headingTxt{
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 8px;
    }
}

@media screen and (max-width: 580px){
    /*.bgGradient{
        background-position-x: -150px;
    }*/
    .heroContainer{
        flex-direction: column;
    }
    .col.imageWrap{
        width: 80%;
        align-items: flex-end;
        z-index: 1;
        margin-left: 80px;
        
    }
    .col.textWrap{
        padding-top: 40px;
        width: 80%;
        z-index: 2;
    }
    .heroImage{
        margin-top: -200px;
    }

}
@media screen and (max-width: 480px) {
    .heroContainer{
        padding-left: 32px;
        padding-right: 32px;
    }
    .col.textWrap{
        width: 100%;
    }
    
}
@media screen and (max-width: 430px){
    /* .button.primary{
        padding: 12px 24px;
    } */
    .heroImage{
        margin-top: -80px;
    }
    .col.imageWrap{
        margin-left: 60px;
    }
    .headingTxt{
        margin-bottom: 16px;
    }
    
}

@media screen and (max-width: 300px){
    .heroImage{
        margin-top: -50px;
    }
    .col.textWrap{
        width: 100%;
    }
    .col.imageWrap{
        margin-left: 50px;
    }
    .subTxt{
        font-size: 16px;
    }

}