.workContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
}
.workSection{
    width: 100%;
}
.textWrap{
    width: 100%;
}
.headingText{
    text-align: center;
    font-size: 48px;
    font-family: 'Poppins';
    color: #5201FF;
    font-weight: 700;
}
.colsWrap{
    display: flex;
    flex-direction: row;
    width: 100%;
    /* padding-bottom: 48px; */
}
.buttonWrap{
    justify-content: center;
    align-items: center;
    padding-bottom: 32px;
    padding-top: 32px;
}
.secondary{
    outline: 1px solid #5201FF;
    transition: all 1000ms;
}
.secondary:hover{
    transform: scale(1.1);
}

.col{
    width: 50%;
    display: flex;
    flex-direction: column;
}
.pic{
    margin-top: 24px;
    max-width: 100%;
    border-radius: 6px;

}
.col.left{
    align-items: center;
    margin-right: 10px;
}
.col.right{
    align-items: center;
    margin-left: 10px;
}
.pic:hover{
    transform: scale(0.98);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: 0.3s ease-in-out;
}


@media screen and (max-width: 1100px) {

    .colsWrap{
        width: 100%;
        padding-left: 32px;
        padding-right: 32px;
        box-sizing: border-box;
    }
    
}

@media screen and (max-width:690px){
    .headingText{
        font-size: 24px;
    }
    .colsWrap{
        display: flex;
        flex-direction: column;
    }
    .col.left{
        width: 100%;
        margin-right: 0px;
    }
    .col.right{
        width: 100%;
        margin-left: 0px;
    }
    .pic{
        max-width: 100%;
        margin-top: 16px;
    }
}