/* .App { */
  /* max-width: 100%; */
  /* text-align: center; */
  /*width: 100%;
  height: 100%;
  display: flex;
  */
/* } */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.container {
  /* background-color: blueviolet; */
  max-width: 1100px;
  margin: auto;
}

.button {
  padding: 12px 36px;
  /* width: 196px; */
  /* height: 48px; */

  border-radius: 8px;
  border-style: none;
  font-weight: 500;
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 24px;
}

.button.primary {
  color: white;
  background: #5201FF;
}

.button.secondary{
  background: white;
  color: #5201FF;
}


/*
.on-top{
  z-index: 99999;
  width: max-content;

}
.mesh-gradient {
  position: absolute;
  overflow: hidden;
  width: 1440px;
  height: 800px;
}

.radial-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(circle, rgba(92, 144, 222, 0.7)20%, transparent 80%),
                  radial-gradient(circle,rgba(255, 103, 255, 0.729)20%, transparent 80%),
                  radial-gradient(circle,rgba(255, 255, 47, 0.884)60%, transparent 80%),
                  radial-gradient(circle, rgb(253, 56, 230)50%, transparent 60%);
  filter: blur(40%);
  background-size: 400% 400%;
  animation: animateGradient 35s ease infinite;
}

.mesh-gradient h1{
  color: black;
  position: relative;
}
.mesh-gradient p{
  color:black;
  position: relative;
}

@keyframes animateGradient {
0% { background-position:0% 0%;}
20% { background-position: 100% -30%;}
30% { background-position: 30% 50%;}
50% { background-position: 80% 100%;}
70% { background-position: 80% 50%;}
80% { background-position: 90% 20%;}
90% { background-position: 0% 90%;}
100%{background-position: 0% 0%;}
}
@media screen and (max-width:769px){
  
  .mesh-gradient div{
    width: 100%;
  }
  
}
*/